import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";
import { pageConfig, onAllPages } from "src/pages";

const config = pageConfig.filter((a) => a.id === "library");

const LibraryPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={[...onAllPages, ...config]} />
    </Container>
  );
};

export default LibraryPage;
